/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@charset "UTF-8";
/* ------------ *Default ------------- */
/* Flex Class */
.fx-column {display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: nowrap;flex-wrap: nowrap;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.flex-center {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.fx-between {-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.align-start {-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;}
.align-center {-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.align-end {-webkit-box-align: end;-ms-flex-align: end;align-items: flex-end;}
.fx-start {-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.fx-center {-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.fx-end {-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.fx {display: -webkit-box;display: -ms-flexbox;display: flex;}
.fx-wrap {-ms-flex-wrap: wrap;flex-wrap: wrap;}
/* Flex Class End */

/* Reserved Class */
.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}
/* Reserved Class End*/

/* Style Default*/
a, a:hover,button, button:hover, .speed {-webkit-transition: all .3s;-o-transition: all .3s;transition: all .3s;}
* {-webkit-box-sizing: border-box;box-sizing: border-box;}
button {border: 0;padding: 0;cursor: pointer;}
* {outline: none;}
/* Style Default End */

body {
  color: #1a1c26;
  font-weight: normal;
  font-family: 'Gilroy';
}

.wrapper {
  overflow: hidden;
  min-height: 100%;
}

.btn {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  border-radius: 29px;
  display: inline-block;
  letter-spacing: 0.30px;
  padding: 6px 7px 6px 40px;
  -webkit-box-shadow: 0px 5px 12px 0px rgba(79, 95, 109, 0.15);
          box-shadow: 0px 5px 12px 0px rgba(79, 95, 109, 0.15);
}

.btn__arrow {
  margin-left: 25px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  width: 46px;height: 46px;
  background: rgba(153, 156, 161, 0.15);
}

.btn__arrow svg {
  margin: auto;
  position: absolute;
  width: 14px;height: 14px;
  top: 0;left: 0;right: 0;bottom: 0;
}

.btn__white {
  color: #e5860f;
  background: #fff;
}

.btn__white svg {
  fill: #b9bbc2;
}

.btn__white:hover {
  background: #f5f5f5;
}

.btn__blue {
  color: #fff;
  background: #0a4b78;
}

.btn__blue svg {
  fill: #6797b9;
}

.btn__blue:hover {
  background: #115b8e;
}

/* ------------ *Main ------------- */

.main {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

/* ------------ *Header ------------- */

.header {
  padding: 20px 0;
  background: #ebf2f7;
}

.logo {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.logo__txt {
  color: #111315;
  margin-top: 4px;
  font-weight: 500;
  font-size: 10.2px;
  letter-spacing: 0.20px;
}

.nav {
  padding: 0 15px;
}

.nav__item:not(:last-child) {
  margin-right: 30px;
}

.nav__link {
  display: block;
  color: #1a1c26;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}

.nav__link:after {
  opacity: 0;
  margin: 0 auto;
  background: #e48104;
  width: 70%;height: 2px;
  top: -9999em;left: 0;right: 0;
  -webkit-transform: translateY(6px);
      -ms-transform: translateY(6px);
          transform: translateY(6px);
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
}

.nav__link:hover:after {
  opacity: 1;
  top: auto;bottom: 0;
}

/* Toggle */

.navbar-toggle {
  z-index: 2;
  display: none;
  background: none;
  margin-right: 25px;
}

.navbar-toggle span {
  display: block;
  position: relative;
  background: #0a4b78;
  width: 30px;height: 3px;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 5px;
}

.navbar-toggle.active span:first-child {
  top: 8px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.navbar-toggle.active span:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.active span:last-child {
  top: -8px;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/* Toggle End */

.header__box {
  position: relative;
}

.header__btn {
  border-radius: 50%;
  width: 34px;height: 34px;
  background: url(../img/icons/i-search.png) no-repeat center #fff;
  -webkit-box-shadow: 0px 5px 12px 0px rgba(79, 95, 109, 0.15);
          box-shadow: 0px 5px 12px 0px rgba(79, 95, 109, 0.15);
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.header__btn:hover {
  -webkit-box-shadow: 0px 5px 12px 0px rgba(79, 95, 109, 0.03);
          box-shadow: 0px 5px 12px 0px rgba(79, 95, 109, 0.03);
}

/* Search */

.search {
  z-index: 3;
  width: 200px;
  display: none;
  top: 50%;
  right: 0;
  position: absolute;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

#ya-site-form0 .ya-site-form__search-input {
  padding: 0 !important;
}

.ya-site-form__input-text {
  height: 34px;
  padding: 0 25px;
  border-radius: 17px;
  border: 0 !important;
  font-size: 14px !important;
  background-image: none !important;
  -webkit-box-shadow: 0px 5px 12px 0px rgba(79, 95, 109, 0.15);
          box-shadow: 0px 5px 12px 0px rgba(79, 95, 109, 0.15);
}

.ya-site-form__submit {
  position: absolute;
  top: 50%;
  right: 10px;
  border: none;
  width: 14px !important;height: 14px !important;
  background: url(../img/icons/i-search.png) center no-repeat !important;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Search END */

/* ------------- *Intro ------------- */

.intro__sl {
  margin-bottom: 0 !important;
}

.intro__sl__item {
  border: 0 !important;
}

.intro__sl__item .container {
  min-height: 568px;
  position: relative;
}

.intro__sl__item_3 .container {
  overflow: hidden;
}

.intro__sl__item .container .row {
  z-index: 1;
  position: relative;
}

.intro__row_top {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.intro__img_1 {
  position: relative;
  top: 20px;left: -40px;
}

.intro__img_2 img {
  margin: auto;
}

.intro__img_3 {
  top: 40px;
  position: relative;
}

.intro__head img {
  margin-right: 15px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.intro__sl__item_3 .intro__row_top .col-xl-6:last-child {
  padding-left: 0;
}

.intro__txt {
  font-size: 37px;
  font-weight: 300;
}

.intro__txt sup {
  font-size: 20px;
}

.intro__body {
  margin-top: 25px;
}

.intro__btn {
  margin-top: 45px;
}

.intro__sl__img {
  opacity: .4;
  position: absolute;
  left: 0;bottom: 14px;
  width: 613px;height: 619px;
  background: url(../img/other/intro-sl-3-img.png) center no-repeat;
}

.intro__sl__item_3 .intro__btn {
  margin-top: 90px;
}

.intro__row_bot {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 30px 0;
}

.intro__small {
  opacity: .35;
  font-size: 12px;
}

.intro__big {
  opacity: .5;
  padding-left: 12px;
  border-left: 2px solid rgba(26, 28, 38, 0.2);
}

/* Sl controls */

.slick-arrow {
  z-index: 1;
  border-radius: 50%;
  width: 40px;height: 40px;
  border: 1px solid rgba(26, 28, 38, 0.2);
}

.slick-arrow:before {
  content: none;
}

.slick-arrow svg {
  width: 10px;height: 10px;
}

.intro__sl .slick-arrow {
  left: 50px;
  background: #fff;
}

.intro__sl .slick-arrow:hover {
  background: rgba(212, 212, 212, 0.2);
  border-color: rgba(212, 212, 212, 0.2);
}

.intro__sl .slick-arrow svg {
  fill: #1a1c26;
}

.intro__sl .slick-prev {
  top: 30%;
  -webkit-transform: translate(0, 0) rotate(90deg);
      -ms-transform: translate(0, 0) rotate(90deg);
          transform: translate(0, 0) rotate(90deg);
}

.intro__sl .slick-next {
  top: auto;right: auto;bottom: 30%;
  -webkit-transform: translate(0, 0) rotate(90deg);
      -ms-transform: translate(0, 0) rotate(90deg);
          transform: translate(0, 0) rotate(90deg);
}

/* Sl controls END */

/* Sl dots */

.intro__sl .slick-dots {
  top: 50%;left: 65px;bottom: auto;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.intro__sl .slick-dots li {
  margin: 15px 0;
  display: block;
  width: 10px;height: 10px;
}

.intro__sl .slick-dots li button {
  padding: 0;
  border-radius: 50%;
  background: #cdced0;
  width: 100%;height: 100%;
}

.intro__sl .slick-dots li button:before {
  content: none;
}

.intro__sl .slick-dots li.slick-active button {
  background: #e5860f;
}

/* Sl dots END */

/* ---------- *Preparation ----------- */

.preparation {
  padding: 84px 0;
  background: url(../img/bg/preparation.jpg) center/cover no-repeat;
}

.preparation__box {
  background: #fff;
  border-radius: 8px;
  padding: 50px 64px;
  -webkit-box-shadow: -66.99px 1.169px 79px 0px rgba(14, 34, 45, 0.14),0px 5px 70px 0px rgba(14, 34, 45, 0.08);
          box-shadow: -66.99px 1.169px 79px 0px rgba(14, 34, 45, 0.14),0px 5px 70px 0px rgba(14, 34, 45, 0.08);
}

.title {
  width: 100%;
  margin: 0 auto;
}

.title__h2 {
  font-size: 40px;
  font-weight: 300;
}

.title__h2 span {
  font-weight: 500;
  display: inline-block;
}

.tabs {
  margin-top: 25px;
}

.tabs__list {
  padding: 6px;
  border-radius: 5px;
  background: #ebf2f7;
}

.tabs__list__name {
  color: #75828c;
  cursor: pointer;
  padding: 0 25px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  min-width: 170px;min-height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.tabs__list__name:hover,
.tabs__list__name.active {
  color: #e48104;
  background: #fff;
  font-weight: bold;
  -webkit-box-shadow: 0px 2px 12px 0px rgba(14, 34, 45, 0.1);
          box-shadow: 0px 2px 12px 0px rgba(14, 34, 45, 0.1);
}

.tabs__list__name:not(:last-child) {
  margin-right: 6px;
}

.tabs__content {
  margin-top: 20px;
}

.tabs__item p {
  color: #6c6d72;
  line-height: 1.5;
  margin-bottom: 25px;
}

.tabs__btn {
  margin-top: 15px;
}

/* ----------- *Pharmacy ------------- */

.pharmacy {
  padding: 70px 0;
}

.pharmacy .title {
  max-width: 800px;
}

.pharmacy .row {
  margin-top: 50px;
}

.pharmacy .row [class*='col'] {
  margin-bottom: 30px;
}

.pharmacy__item {
  padding: 5px;
  height: 96px;
  display: block;
  background: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.pharmacy__item img {
  opacity: .8;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.pharmacy__item:hover {
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04);
}

.pharmacy__item:hover img {
  opacity: 1;
}

.pharmacy__address a {
  color: #e48104;
  font-weight: 500;
  text-decoration: underline;
}

.pharmacy__address a:hover {
  text-decoration: none;
}

/* ---------- *Information ----------- */

.information {
  padding: 75px 0 90px;
  background: url(../img/other/information-bg.png) center/auto no-repeat #5083af;
}

.information .title {
  color: #fff;
}

.information .row {
  margin-top: 35px;
}

.information__sl .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.information__sl .slick-slide {
  height: auto;
}

.information__item {
  height: 100%;
}

.information__item {
  display: block;
  background: #fff;
  overflow: hidden;
  border-radius: 12px;
  -webkit-box-shadow: 0px 7px 21px 0px rgba(17, 41, 60, 0.1);
          box-shadow: 0px 7px 21px 0px rgba(17, 41, 60, 0.1);
}

.information__img img {
  width: 100%;
}

.information__txt {
  padding: 25px 30px 35px;
}

.information__txt h3 {
  color: #1a1c26;
  font-size: 30px;
  font-weight: 500;
}

.information__txt p {
  color: #6c6d72;
  line-height: 1.5;
  margin-top: 15px;
}

/* Sl contols */

.information__sl .slick-arrow {
  top: -79px;
  border-color: rgba(255, 255, 255, 0.35);
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

.information__sl .slick-arrow svg {
  fill: #fff;
}

.information__sl .slick-arrow:hover {
  background: rgba(255, 255, 255, 0.35);;
}

.information__sl .slick-prev {
  left: auto;right: 68px;
}

.information__sl .slick-next {
  right: 15px;
}

/* Sl contols END */

/* ------------- *Footer ------------- */

.footer {
  padding-top: 65px;
  background: #ebf2f7;
}

.footer .col-auto:not(:first-child) {
  margin-top: 8px;
}

.footer__item {
  margin-bottom: 25px;
  letter-spacing: 0.20px;
}

.footer__name {
  color: #bdc3c8;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.35;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer__icon {
  margin-right: 5px;
  width: 16px;height: 14px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

i.i-tel {
  background: url(../img/icons/i-tel.png) 0 center no-repeat;
}

i.i-email {
  background: url(../img/icons/i-email.png) 0 center no-repeat;
}

i.i-time {
  background: url(../img/icons/i-time.png) 0 center no-repeat;
}

i.i-address {
  background: url(../img/icons/i-address.png) 0 center no-repeat;
}

.footer__txt {
  color: #8a8f97;
  margin-top: 5px;
  line-height: 1.3;
  max-width: 300px;width: 100%;
}

.footer__txt a {
  color: inherit;
}

.footer__txt a:hover {
  text-decoration: underline;
}

.footer__text {
  color: #8a8f97;
  padding: 20px 0;
  font-size: 13.5px;
  line-height: 22px;
  margin-bottom: 55px;
  letter-spacing: 0.24px;
  border-top: 1px solid #dbe1ea;
  border-bottom: 1px solid #dbe1ea;
}

.footer__contraindications {
  color: #8a8f97;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 300;
  font-family: 'Oswald';
  text-transform: uppercase;
}

.footer__bot {
  color: #adb2b6;
  font-size: 14px;
  margin-top: 55px;
  padding: 16px 15px;
  background: rgba(219, 227, 232, 0.7);
}

/* =================================== */
/*             Page: Info              */
/* =================================== */

.info {
  color: #fff;
  background: url(../img/bg/info.jpg) center/cover no-repeat;
}

.info .row {
  padding: 60px 0;
  min-height: 434px;
}

.info__box {
  padding-left: 40px;
}

.info__img img {
  display: block;
}

.info__txt {
  margin-top: 15px;
  font-weight: bold;
  max-width: 330px;width: 100%;
}

.info__name {
  font-size: 37px;
  font-weight: 300;
  margin-top: 25px;
}

/* ------------ *Useful ------------- */

.useful {
  padding: 75px 0 55px;
}

.title_blue {
  color: #0a4b78;
}

.useful .row {
  margin-top: 15px;
}

.useful__txt {
  line-height: 1.5;
  padding: 10px 40px 0 0;
}

.useful__txt p:not(:last-child) {
  margin-bottom: 25px;
}

.useful__img {
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
}

.useful__img img {
  width: 100%;
  display: block;
}

/* ------------ *Disease ------------- */

.disease {
  padding-bottom: 60px;
}

.info-title {
  color: #fff;
  font-size: 37px;
  font-weight: 300;
  padding: 13px 30px;
  border-radius: 10px;
  background: #0a4b78;
}

.disease__item {
  margin-top: 35px;
}

.disease-accordion__header {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.disease-accordion__header .accordion__icon {
  background: none;
  margin: 5px 15px 0 0;
  display: inline-block;
  width: 17px;height: 17px;
}

.disease-accordion__header .accordion__icon span {
  border-radius: 0;
  background: #e48104;
}

.disease__item.active .accordion__icon span:first-child {
  opacity: 0;
}

.disease-accordion__body {
  display: none;
}

.disease-accordion__body .row {
  margin-top: 20px;
}

.disease__img {
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
}

.disease__img img {
  width: 100%;
  display: block;
}

.disease__txt {
  line-height: 1.5;
  margin-top: 15px;
}

.mt-0 {
  margin-top: 0;
}

.mt-35 {
  margin-top: 35px;
}

.disease__item ul {
  margin: 20px 0;
}

.disease__item li {
  line-height: 1.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.disease__item li:before {
  content: " ";
  margin: 5px 12px 0 0;
  display: inline-block;
  width: 15px;height: 13px;
  background: url(../img/icons/i-check.png) center no-repeat;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.disease__item:nth-child(2) strong {
  display: block;
  margin-top: 25px;
}

.disease__item_3 {
  margin-top: 45px;
}

.functions,
.caution {
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  background: #e6edf1;
  padding: 25px 85px 30px 50px;
}

.functions:before,
.caution:before {
  width: 3px;
  background: #0a4b78;
  top: 0;left: 0;bottom: 0;
}

.functions h4 {
  font-size: 22px;
}

.functions .row {
  margin-top: 20px;
}

.functions__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.functions__icon {
  margin: 3px 20px 0 0;
  display: inline-block;
  width: 15px;height: 13px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

i.i-check {
  background: url(../img/icons/i-check.png) center no-repeat;
}

.functions__txt {
  color: #7c8295;
}

.disease__item_4 .row {
  margin-top: 20px;
}

/* ----------- *Exercises ------------- */

.exercises {
  background: #ebf2f7;
  padding: 60px 0 90px;
}

.exercises__item {
  padding: 30px 0;
  background: #fff;
  margin-top: 20px;
  border-radius: 10px;
}

.exercises__box {
  padding-left: 30px;
}

.exercises__box ul {
  line-height: 1.5;
}

.exercises__box li {
  margin-top: 17px;
}

.exercises__box li span {
  color: #0a4b78;
  margin-right: 5px;
  font-weight: bold;
  display: inline-block;
}

.caution {
  padding: 23px 35px;
  background: #fbe9e9;
}

.caution:before {
  background: #da2828;
}

.caution h4 {
  font-size: 26px;
}

.caution .row {
  margin-top: 15px;
}

.caution p {
  color: #da2828;
  line-height: 1.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.caution p:before {
  content: " ";
  margin: 5px 15px 0 0;
  width: 13px;height: 13px;
  background: url(../img/icons/i-close.png) center no-repeat;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.xercises__img {
  padding: 0 28px;
}

.xercises__img img {
  display: block;
  margin: 0 auto;
}

/* =================================== */
/*              Page: Faq              */
/* =================================== */

/* ---------- *Breadcrumb ------------ */

.breadcrumb {
  padding: 15px 0;
  background: rgba(223, 232, 238, 0.2);
}

.breadcrumb__item {
  font-size: 14px;
}

.breadcrumb__item.active {
  color: #0a4b78;
  font-weight: 600;
}

.breadcrumb__item+.breadcrumb__item {
  padding-left: 15px;
}

.breadcrumb__item+.breadcrumb__item::before {
  content: "»";
  color: #a6a7ad;
  font-weight: normal;
  padding-right: 11px;
  display: inline-block;
}

.breadcrumb__item a {
  color: #a6a7ad;
}

.breadcrumb__item a:hover {
  text-decoration: underline;
}

/* -------------- *Faq -------------- */

.faq {
  padding: 50px 0 70px;
}

.title_border {
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(223, 232, 238, 0.5);
}

.accordion {
  margin-top: 35px;
}

.accordion li {
  background: #fff;
}

.accordion li:not(:last-child) {
  margin-bottom: 20px;
}

.accordion-header {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  background: #ebf2f7;
  padding: 15px 25px 15px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.accordion-header p {
  padding-right: 25px;
}

.accordion__icon {
  border-radius: 50%;
  position: relative;
  background: #0a4b78;
  width: 50px;height: 50px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.accordion__icon span {
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 50%;left: 50%;
  width: 3px;height: 16px;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.accordion__icon span:last-child {
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
      -ms-transform: translate(-50%, -50%) rotate(-90deg);
          transform: translate(-50%, -50%) rotate(-90deg);
}

.accordion-header:hover,
.accordion li.active .accordion-header {
  color: #fff;
  font-weight: bold;
  background: #0a4b78;
}

.accordion-header:hover .accordion__icon,
.accordion li.active .accordion__icon {
  background: #fff;
}

.accordion-header:hover .accordion__icon span,
.accordion li.active .accordion__icon span {
  background: #0a4b78;
}

.accordion-header:hover .accordion__icon span:first-child,
.accordion li.active .accordion__icon span:first-child {
  opacity: 0;
}

.accordion-body {
  display: none;
  color: #6c6d72;
  line-height: 1.5;
  margin-top: -10px;
  border-radius: 6px;
  padding: 40px 50px 30px;
  -webkit-box-shadow: 0px 8px 15px 0px rgba(14, 34, 45, 0.08);
          box-shadow: 0px 8px 15px 0px rgba(14, 34, 45, 0.08);
}

.accordion-body p:not(:last-child) {
  margin-bottom: 25px;
}

/* =================================== */
/*            Page: Selling            */
/* =================================== */

.selling {
  padding: 50px 0 47px;
}

.selling .box:not(:last-child) {
  margin-bottom: 62px;
}

.selling .title span {
  font-weight: 500;
}

.selling .box .row {
  margin-top: 35px;
}

.selling .box .row [class*='col'] {
  margin-bottom: 38px;
}

.selling__item {
  display: block;
  border-radius: 6px;
  text-align: center;
  background: #ebf2f7;
  padding: 20px 20px 15px;
}

.selling__item .pharmacy__item {
  margin: 0 auto;
  border-radius: 6px;
  max-width: 165px;height: 96px;
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
}

.selling__item .pharmacy__item img {
  opacity: .8;
}

.selling__item:hover .pharmacy__item img {
  opacity: 1;
}

.selling__txt {
  color: #f39824;
  font-size: 22px;
  font-weight: 500;
  margin-top: 15px;
  display: inline-block;
  text-decoration: underline;
}

.selling__txt:hover {
  text-decoration: none;
}

/* =================================== */
/*           Page: Contacts            */
/* =================================== */

.contacts {
  padding: 50px 0 75px;
}

.contacts .title {
  margin-bottom: 30px;
}

.contacts__box {
  padding-right: 50px;
}

.contacts__item {
  padding: 25px;
  min-height: 105px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contacts__item:not(:last-child) {
  margin-bottom: 18px;
}

.contacts__item__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.contacts__icon {
  margin-top: 2px;
  border-radius: 50%;
  width: 34px;height: 34px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

i.i-point {
  background: url(../img/icons/i-point.png) center no-repeat #0a4b78;
}

i.i-phone {
  background: url(../img/icons/i-phone.png) center no-repeat #0a4b78;
}

i.i-mail {
  background: url(../img/icons/i-mail.png) center no-repeat #0a4b78;
}

.contacts__txt {
  margin-left: 15px;
}

.contacts__name {
  opacity: .4;
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
}

.contacts__txt p {
  line-height: 1.3;
}

.contacts__txt p a {
  color: #1a1c26;
}

.contacts__txt a:hover {
  text-decoration: underline;
}

.contacts__form {
  height: 100%;
}

.contacts__form .form {
  padding: 35px 40px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 5px 50px 0px rgba(14, 34, 45, 0.12);
          box-shadow: 0px 5px 50px 0px rgba(14, 34, 45, 0.12);
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.contacts__form .form .row {
  margin-left: -7px;margin-right: -7px;
}

.contacts__form .form [class*='col'] {
  padding: 0 7px;
}

.form__field {
  display: block;
  position: relative;
}

.form__icons {
  position: absolute;
  top: 50%;left: 18px;
  width: 19px;height: 19px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

i.i-user {
  background: url(../img/icons/i-user.png) center no-repeat;
}

i.i-email-2 {
  background: url(../img/icons/i-email-2.png) center no-repeat;
}

i.i-phone-2 {
  background: url(../img/icons/i-phone-2.png) center no-repeat;
}

.form__field:not(:last-child) {
  margin-bottom: 15px;
}

.form__field input,
.form__field textarea {
  border: 0;
  width: 100%;
  color: #1a1c26;
  display: block;
  font-size: 16px;
  font-weight: normal;
  border-radius: 10px;
  font-family: 'Gilroy';
  background: rgba(10, 75, 120, 0.07);
}

.form__field *::-webkit-input-placeholder {color: #1a1c26;}
.form__field *::-moz-placeholder          {color: #1a1c26;}/* Firefox 19+ */
.form__field *:-moz-placeholder           {color: #1a1c26;}/* Firefox 18- */
.form__field *:-ms-input-placeholder      {color: #1a1c26;}

.form__field input {
  height: 55px;
  padding: 0 20px;
}

.contacts__form .form .form__field input {
  padding-left: 50px;
}

.form__field textarea {
  resize: none;
  height: 195px;
  padding: 20px 35px;
}

.contacts__btn {
  margin-top: 25px;
  width: 268px;height: 58px;
  padding: 6px 52px 6px 25px;
}

.contacts__btn .btn__arrow {
  position: absolute;
  top: 50%;right: 7px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.map {
  width: 100%;height: 320px;
}

/* =================================== */
/*            Page: About              */
/* =================================== */

.about {
  padding: 50px 0 60px;
}

.about .title_border {
  padding-bottom: 20px;
}

.title__txt {
  width: 100%;
  color: #6c6d72;
  line-height: 1.5;
  margin-top: 15px;
}

.about .title__txt {
  max-width: 1030px;
}

.about .row {
  margin-top: 35px;
}

.about .row [class*='col'] {
  margin-bottom: 35px;
}

.about .selling__item {
  height: 100%;
  padding: 20px 10px;
}

.about__img {
  display: block;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid #fff;
  width: 98px;height: 98px;
}

.about__img img {
  display: block;
  width: 100%;height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.about__txt {
  font-size: 18px;
  line-height: 1.3;
  margin-top: 10px;
}

.about__txt sup {
  color: #e48207;
  font-size: 12px;
  font-weight: 600;
}

.about__list {
  line-height: 2;
  margin: 0 auto;
  max-width: 900px;width: 100%;
}

.about__list__item {
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about__list__item:not(:last-child) {
  margin-right: 20px;
}

.about__num {
  color: #6c6d72;
  margin-right: 4px;
}

.about__link {
  color: #e48207;
  text-decoration: underline;
}

.about__link:hover {
  text-decoration: none;
}

/* ------------- *Desc ------------- */

.desc {
  background: #5083af;
  padding: 90px 0 70px;
}

.desc .box {
  background: #fff;
  border-radius: 12px;
  padding: 50px 35px 65px 55px;
  -webkit-box-shadow: 0px 7px 21px 0px rgba(17, 41, 60, 0.1);
          box-shadow: 0px 7px 21px 0px rgba(17, 41, 60, 0.1);
}

.desc__txt {
  line-height: 1.5;
}

.desc__txt p:not(:last-child) {
  margin-bottom: 25px;
}

/* =================================== */
/*         Page: Specialists           */
/* =================================== */

.specialists {
  padding: 45px 0 65px;
}

.specialists .box {
  margin-top: 45px;
}

.specialists__item {
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 22px 50px 22px 22px;
  -webkit-box-shadow: 0px 5px 50px 0px rgba(14, 34, 45, 0.12);
          box-shadow: 0px 5px 50px 0px rgba(14, 34, 45, 0.12);
}

.specialists__img a,
.specialists__img img {
  display: block;
}

.specialists__txt {
  height: 100%;
  padding-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.specialists__txt__box {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.specialists__txt h3 {
  font-size: 22px;
  font-weight: bold;
}

.specialists__txt p {
  color: #6c6d72;
  line-height: 1.5;
  margin-top: 20px;
}

.specialists__txt__btn {
  margin-top: 50px;
}

/* ------------- *Modal ------------- */

.modal {
  z-index: 99;
  display: none;
  position: fixed;
  overflow-y: auto;
  top: 0;left: 0;right: 0;bottom: 0;
  background: rgba(2, 9, 14, 0.4);
}

.modal__fullScreen {
  padding: 30px 0;
  min-height: 100%;
}

.modal__box {
  margin: 0 auto;
  max-width: 946px;width: 96%;
}

.modal_login .modal__box {
  background: #fff;
  border-radius: 8px;
  padding: 60px 115px 80px;
  -webkit-box-shadow: 0px 5px 50px 0px rgba(14, 34, 45, 0.12);
          box-shadow: 0px 5px 50px 0px rgba(14, 34, 45, 0.12);
}

.modal_login .title__txt {
  margin-top: 30px;
}

.modal__group {
  margin-top: 35px;
}

.modal__group .btn {
  padding: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 197px;width: 100%;height: 58px;
}

.modal__group .btn__white {
  color: #0a4b78;
  margin-right: 20px;
  background: rgba(10, 75, 120, 0.1);
  -webkit-box-shadow: none;
          box-shadow: none;
}

.modal__group .btn__white:hover {
  background: rgba(10, 75, 120, 0.2);
}

.modal__group .btn__blue {
  padding-right: 42px;
}

.modal__group .btn .btn__arrow {
  position: absolute;
  top: 50%;right: 6px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* ------------ *Default ------------- */
/* ------------ *Default ------------- */
/* ------------ *Default ------------- */
/* ------------ *Default ------------- */
/* --------- *Before::After ---------- */
.nav__link:after,
.functions:before,
.caution:before {
  content: " ";
  position: absolute;
}
